import React, { ReactElement, FC } from "react"

import { I_Navigation } from "../../global/interfaces"
import HeaderDesktop from "./header-desktop/header-desktop"
import HeaderMobile from "./header-mobile/header-mobile"

import "./header.less"

const HeaderComponent: FC<I_Navigation> = ({
  links,
  location,
  baseUrl,
}: I_Navigation): ReactElement => {
  return (
    <>
      <HeaderDesktop links={links} location={location} baseUrl={baseUrl} />
      <HeaderMobile links={links} location={location} baseUrl={baseUrl} />
    </>
  )
}

export default HeaderComponent
