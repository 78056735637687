import React, { ReactNode, ReactElement, FC } from "react"
import { Layout } from "antd"

import "./content.less"

const { Content } = Layout

type Props = {
  children: ReactNode
}

const LayoutComponent: FC<Props> = ({ children }: Props): ReactElement => {
  return <Content className="content">{children}</Content>
}

export default LayoutComponent
