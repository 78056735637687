import React, { ReactNode, ReactElement, FC } from "react"
import { Layout } from "antd"

import Header from "../header/header"
import Content from "../content/content"
import Footer from "../footer/footer"
import { I_Navigation, I_Footer, I_Location } from "../../global/interfaces"

import "./layout.less"

type Props = {
  children: ReactNode
  navigation: I_Navigation
  footer: I_Footer
  location: I_Location
  baseUrl: string
}

const LayoutComponent: FC<Props> = ({
  children,
  navigation,
  footer,
  location,
  baseUrl,
}: Props): ReactElement => {
  return (
    <Layout className="layout">
      <Header {...navigation} location={location} baseUrl={baseUrl} />
      <Content>{children}</Content>
      <Footer {...footer} baseUrl={baseUrl} />
    </Layout>
  )
}

export default LayoutComponent
