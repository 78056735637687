import React, { ReactElement, FC, ReactNode } from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import { LinkTarget } from "../../global/enums"

interface I_LinkProps {
  className?: string
  link: string
  target: LinkTarget
  children: ReactNode
}

const LinkComponent: FC<I_LinkProps> = ({
  className,
  link,
  target,
  children,
}: I_LinkProps): ReactElement => {
  if (target === LinkTarget.Self || link.startsWith("http")) {
    return (
      <OutboundLink
        href={link}
        target={target}
        className={className ? className : ""}
      >
        {children}
      </OutboundLink>
    )
  }

  return (
    <OutboundLink href={link} className={className ? className : ""}>
      {children}
    </OutboundLink>
  )
}

export default LinkComponent
