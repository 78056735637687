import React, { ReactElement, FC } from "react"
import { Layout, Menu, Button, Dropdown } from "antd"
import { SearchOutlined, MenuOutlined } from "@ant-design/icons"

import LogoMobile from "../../../assets/logo-mobile.svg"
import Link from "../../link/link"
import { I_Navigation, I_Link } from "../../../global/interfaces"
import { LinkTarget } from "../../../global/enums"

import "./header-mobile.less"

const { Header } = Layout

const HeaderComponent: FC<I_Navigation> = ({
  links,
  location,
  baseUrl,
}: I_Navigation): ReactElement => {
  const [, country, language] = baseUrl.split("/")
  const url = country ? (language ? baseUrl : `${baseUrl}/${country}`) : ""

  const menu = (
    <Menu selectedKeys={[location?.pathname]}>
      {links.map(({ text, link, target }: I_Link) => (
        <Menu.Item key={link}>
          <Link link={`${url}/${link}`} target={target}>
            {text}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Header className="header-mobile">
      <nav className="header-mobile__navigation">
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button shape="circle" icon={<MenuOutlined />} />
        </Dropdown>
        <Link
          link={url}
          target={LinkTarget.Self}
          className="header-mobile__logo"
        >
          <LogoMobile height="40" />
        </Link>
        <div className="header-mobile__button">
          <Button type="primary" shape="circle" icon={<SearchOutlined />} />
        </div>
      </nav>
    </Header>
  )
}

export default HeaderComponent
