import React, { ReactElement, FC } from "react"
import { Layout, Menu, Button } from "antd"
import { SearchOutlined } from "@ant-design/icons"

import LogoDesktop from "../../../assets/logo-desktop.svg"
import Link from "../../link/link"
import { I_Navigation, I_Link } from "../../../global/interfaces"
import { LinkTarget } from "../../../global/enums"

import "./header-desktop.less"

const { Header } = Layout

const HeaderDesktopComponent: FC<I_Navigation> = ({
  links,
  location,
  baseUrl,
}: I_Navigation): ReactElement => {
  const [, country, language] = baseUrl.split("/")
  const url = country ? (language ? baseUrl : `${baseUrl}/${country}`) : ""
  return (
    <Header className="header-desktop">
      <nav className="header-desktop__navigation">
        <Link
          link={url}
          target={LinkTarget.Self}
          className="header-desktop__logo"
        >
          <LogoDesktop height="40" />
        </Link>
        <Menu
          mode="horizontal"
          className="header-desktop__menu"
          selectedKeys={[location?.pathname]}
        >
          {links.map(({ text, link, target }: I_Link) => (
            <Menu.Item key={link}>
              <Link link={`${url}/${link}`} target={target}>
                {text}
              </Link>
            </Menu.Item>
          ))}
        </Menu>
        <div className="header-desktop__button">
          <Link
            link={url}
            target={LinkTarget.Self}
            className="header-desktop__logo"
          >
            <Button type="primary" shape="circle" icon={<SearchOutlined />} />
          </Link>
        </div>
      </nav>
    </Header>
  )
}

export default HeaderDesktopComponent
