export enum LinkTarget {
  Blank = "_blank",
  Self = "_self",
}

export enum ErrorType {
  Required = "required",
  Name = "invalidName",
  ISBN = "invalidISBN",
  Email = "invalidEmail",
}

export enum SocialNetwork {
  Facebook = "facebook",
  Twitter = "twitter",
  Instagram = "instagram",
}

export enum TypeRule {
  Email = "email",
}

export enum PaapiLanguage {
  ES = "es_ES",
  IT = "it_IT",
  EN = "en_US",
}

export enum PaapiClientHost {
  ES = "webservices.amazon.es",
  IT = "webservices.amazon.it",
}

export enum PaapiPartnerTag {
  ES = "textolibrosco-21",
  IT = "letturalia0d-21",
}

export enum PaapiMarketPlace {
  ES = "www.amazon.es",
  IT = "www.amazon.it",
}

export enum SearchType {
  Author = "author",
  Title = "title",
  ISBN = "isbn",
}

export enum NotificationPlacement {
  BottomRight = "bottomRight",
}

export enum OpenGraphType {
  Website = "website",
  Article = "article",
}

export enum Stores {
  AmazonEs = "amazonIt",
  AmazonIt = "amazonIt",
  CasaDelLibro = "casaDelLibro",
  Agapea = "agapea",
  Imosver = "imosver",
  Mondadori = "mondadori",
  Libraccio = "libraccio",
  Feltrinelli = "feltrinelli",
}
