import React, { ReactElement, FC } from "react"
import { Layout, Typography, Row, Col } from "antd"

import Link from "../link/link"
import { I_Footer, I_LinkList, I_Link } from "../../global/interfaces"

import "./footer.less"

const { Footer } = Layout
const { Text, Title } = Typography

const FooterComponent: FC<I_Footer> = ({
  copyright,
  linkList,
  baseUrl,
}: I_Footer): ReactElement => {
  const [, country, language] = baseUrl.split("/")
  const url = country ? (language ? baseUrl : `${baseUrl}/${country}`) : ""

  return (
    <Footer className="footer">
      <Row className="footer__wrapper">
        {linkList.map(({ id, title, links }: I_LinkList) => (
          <Col xs={12} lg={6} key={id} className="footer__list">
            <Title level={5} className="footer__list-title">
              {title}
            </Title>
            {links.map(({ id, text, link, target }: I_Link) => (
              <Link
                key={id}
                link={
                  target === "_blank"
                    ? link
                    : link.includes("*")
                    ? link.replace("*", "")
                    : `${url}/${link}`
                }
                target={target}
                className="footer__list-link"
              >
                {text}
              </Link>
            ))}
          </Col>
        ))}
        <Col span={24} className="footer__copyright">
          <Text className="footer__copyright-text">{copyright}</Text>
        </Col>
      </Row>
    </Footer>
  )
}

export default FooterComponent
