const isBrowser = typeof window !== "undefined"

export const getWindowNavigatorLanguage = (): string => {
  if (!isBrowser) {
    return ""
  }

  return window.navigator.language
}

export const getWindowLocationPathname = (): string => {
  if (!isBrowser) {
    return ""
  }

  return window.location.pathname
}

export const getWindowLocationHref = (): string => {
  if (!isBrowser) {
    return ""
  }

  return window.location.pathname
}

export const getWindowLocationHost = (): string => {
  if (!isBrowser) {
    return ""
  }

  return window.location.host
}

export const getWindowLocationSearch = (): string => {
  if (!isBrowser) {
    return ""
  }

  return window.location.search.replace("?", "")
}

export const getWindowLocationSearchObj = (): any => {
  if (!isBrowser) {
    return null
  }

  const windowLocationSearch = getWindowLocationSearch()
  const searchParams = windowLocationSearch.split("&")

  return searchParams.reduce((acc, curr) => {
    const [key, value] = curr.split("=")

    return {
      ...acc,
      [key]: value,
    }
  }, {})
}
